import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useContext } from 'react';
import { ConnectivityContext } from './Connectivity';
import './css/gallery.css';

const GalleryCard = ({ obj, enlarged, setEnlarged }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0px 0px 50px 0px',
    threshold: 0
  });

  const handleClick = () => {
    if (enlarged !== obj.id) {
      setEnlarged(obj.id);
    }
  };

  return (
    <div
      className={`galleryobject-gallery-card ${enlarged === obj.id ? 'enlarged' : ''}`}
      onClick={enlarged === obj.id ? undefined : handleClick}
    >
      <img
        ref={ref}
        className="galleryobject-image"
        alt={obj.alt}
        src={inView ? obj.src : ''}
      />
      <div className="galleryobject-container">
        <h1 className="galleryobject-heading">
          <span>{obj.title}</span><br />
          <a className="ElementMarketLink" href={obj.link} target="_blank" rel="noopener noreferrer">View on Element Market</a>
        </h1>
      </div>
      <div className="galleryobject-id">No. {obj.id}</div>

      {enlarged === obj.id && 
        <div className="close-button" onClick={(e) => { e.stopPropagation(); setEnlarged(null); }}>
          <img src="./assets/close.svg" alt="Close Button" />
        </div>
      }
    </div>
  );
};

const Gallery = () => {
  const { connected, name, walletAddress, connect } = useContext(ConnectivityContext);
  const [searchedNFTs, setSearchedNFTs] = useState([]);
  const [loadNFTs, setLoadNFTs] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [galleryObjects, setGalleryObjects] = useState([]);
  const [enlarged, setEnlarged] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const imageCount = 665;
  const imageFormat = "webp";
  const imagePath = "./assets/nft_images/";

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  useEffect(() => {
    const imageIndexes = [...Array(imageCount).keys()];
    const shuffledIndexes = shuffleArray(imageIndexes);
    const newGalleryObjects = shuffledIndexes.map((i) => ({
      alt: "image",
      src: `${imagePath}${i}.${imageFormat}`,
      title: `zkAmurai No. ${i}`,
      link: `https://element.market/assets/zksync/0x205303ba7ee0d3098b29afed86158bf162ba125a/${i}`,
      id: i,
      visible: true,
    }));
    setGalleryObjects(newGalleryObjects);
  }, []);

const fetchMyNFTs = async (address) => {
  try {
    setLoading(true);
    const collectionAddress = '0x205303ba7ee0d3098b29afed86158bf162ba125a';
    const lowerCaseAddress = address.toLowerCase(); 
    const apiEndpoint = `https://api.zkmarkets.com/zksync-era/collections/${collectionAddress}/nfts?ownerAddress=${lowerCaseAddress}`;
    console.log('Calling API Endpoint:', apiEndpoint)


    const response = await fetch(apiEndpoint);
    const data = await response.json();

    console.log('API response:', data);

    if (Array.isArray(data)) {
      const newGalleryObjects = data.map(item => ({
        alt: item.name,
        src: item.originalImageUrl,
        title: item.name,
        link: `https://element.market/assets/zksync/${collectionAddress}/${item.tokenId}`,
        id: item.tokenId,
        visible: true,
        owner: item.ownerAddress,
      }));

      setGalleryObjects(newGalleryObjects);
      setSearchedNFTs(newGalleryObjects);
    } else {
      console.log('Error fetching NFTs:', data);
    }
  } catch (error) {
    console.error("Error fetching NFTs:", error);
  } finally {
    setLoading(false);
  }
};


  const filterGallery = (address) => {
    if (address && loadNFTs) {  // Conditionally fetch NFTs only when loadNFTs is true
      fetchMyNFTs(address);
      setShowBackButton(true); // Show back button when filtering
    } else {
      setShowBackButton(false); // Hide back button when not filtering
    }
  };

  useEffect(() => {
    if (connected) {
      filterGallery(walletAddress);
    }
  }, [connected, walletAddress, loadNFTs]);

  const handleMyZkAmuraiClick = async () => {
    console.log('Button clicked. Connected:', connected, 'walletAddress:', walletAddress);
    if (connected) {
      setLoadNFTs(true);  // Set loadNFTs to true on button click
      filterGallery(walletAddress);
    } else {
      await connect(); // Here, you have to make sure connect() returns a promise
      if (walletAddress) {
        setLoadNFTs(true);  // Set loadNFTs to true after connected
        filterGallery(walletAddress);
      }
    }
  }
  
  useEffect(() => {
    if (searchValue) {
      const isEthereumAddress = /^0x[a-fA-F0-9]{40}$/.test(searchValue);
  
      if (isEthereumAddress) {
        fetchMyNFTs(searchValue);
        setGalleryObjects(searchedNFTs);
      } else {
        setGalleryObjects((prevObjects) =>
          prevObjects.map((obj) => ({
            ...obj,
            visible: obj.id === Number(searchValue),
          }))
        );
      }
    } else {
      const imageIndexes = [...Array(imageCount).keys()];
      const shuffledIndexes = shuffleArray(imageIndexes);
      const newGalleryObjects = shuffledIndexes.map((i) => ({
        alt: "image",
        src: `${imagePath}${i}.${imageFormat}`,
        title: `zkAmurai No. ${i}`,
        link: `https://element.market/assets/zksync/0x205303ba7ee0d3098b29afed86158bf162ba125a/${i}`,
        id: i,
        visible: true,
      }));
      setGalleryObjects(newGalleryObjects);
    }
  }, [searchValue]);


  const updateGallery = () => {
    const imageIndexes = [...Array(imageCount).keys()];
    const shuffledIndexes = shuffleArray(imageIndexes);
    const newGalleryObjects = shuffledIndexes.map((i) => ({
      alt: "image",
      src: `${imagePath}${i}.${imageFormat}`,
      title: `zkAmurai No. ${i}`,
      link: `https://element.market/assets/zksync/0x205303ba7ee0d3098b29afed86158bf162ba125a/${i}`,
      id: i,
      visible: true,
    }));
    setGalleryObjects(newGalleryObjects);
    setShowBackButton(false);
    setSearchValue("");
    setLoadNFTs(false); // Reset the loadNFTs state
    setEnlarged(null);
  };
  

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    
    setSearchValue(inputValue);
      
    const isEthereumAddress = /^0x[a-fA-F0-9]{40}$/.test(inputValue);
      
    if (isEthereumAddress) {
      fetchMyNFTs(inputValue.toLowerCase());
    }
  };


  const visibleCards = galleryObjects.filter((obj) => obj.visible);

  const galleryStyle =
    visibleCards.length === 1 ? { display: "flex", justifyContent: "center" } : {};

  if (loading) {
    return (
      <div className="loading">
        <img src="./assets/goblin.svg" alt="Loading your zkAmurai you simp..." />
      </div>
    );
  }

  return (
    <div className="gallery">
      <div className="gallery-header-2">
        <div className="connectivity-status">
          {connected ? name : "Not Connected"}
        </div>
        <div className="header-content">
          <span className="gallery-text">Showing {visibleCards.length === imageCount ? visibleCards.length + 1 : visibleCards.length}</span>
          <div className="gallery-icon" onClick={updateGallery}>
            <img src="./assets/arrows.svg" alt="Arrow Icon" />
          </div>
          <input
            type="text"
            id="nft-search"
            className="search-box"
            placeholder="Filter by address or No."
            value={searchValue}
            onChange={handleSearchChange}
          />
          <div className="wallet-btn" onClick={() => {
            // If the gallery is in enlarged view, return without doing anything
            if (enlarged) {
              return;
            }
            
            console.log('Button clicked. Connected:', connected, 'walletAddress:', walletAddress);
            if (showBackButton) {
              updateGallery(); // Call updateGallery to go back to full gallery
            } else if (connected) {
              handleMyZkAmuraiClick(); // Call your existing click handler
            } else {
              connect();
            }
          }}>
            {showBackButton ? "Back" : "My zkAmurai"}
          </div>
        </div>
      </div>
      <div className={`blur-overlay ${enlarged ? "show" : ""}`}></div>
      <div className="gallery-grid">
        <div className="gallery-cards" id="galleryGrid" style={galleryStyle}>
          {visibleCards.map((obj, idx) => (
            <GalleryCard
              key={idx}
              obj={obj}
              enlarged={enlarged}
              setEnlarged={setEnlarged}
            />
          ))}
        </div>
      </div>
    </div>
  );
};


export default Gallery;
