import React from 'react';
import './css/footer.css';

const Footer = () => {
  return (
    <footer>
      <span>Join for the Art, stay for the Alpha!</span>
      <a href="https://discord.gg/FZRm6ErYeg" className="footer-link">
        <img src="./assets/discord.svg" className="discord-logo" alt="Discord" />
      </a>
    </footer>
  );
};

export default Footer;
