import React, { useState, useEffect, useContext } from 'react';
import { ConnectivityContext } from './Connectivity';
import { Link, useLocation } from 'react-router-dom';
import './css/header.css';

const Header = ({ muted, handleMute }) => {
  const location = useLocation();
  const { connected, connect, disconnect } = useContext(ConnectivityContext);
  const [scrolled, setScrolled] = useState(false);

  const toggleMobileMenu = () => {
    const body = document.querySelector('body');
    const mobileMenu = document.querySelector('.mobile-menu');
    const hamburgerIcon = document.querySelector('#hamburger-icon');
    mobileMenu.classList.toggle('open');
    hamburgerIcon.classList.toggle('change');
    body.classList.toggle('menu-active');
    mobileMenu.style.display = mobileMenu.style.display === 'block' ? 'none' : 'block';

    if (body.classList.contains('menu-active')) {
      body.style.overflow = 'hidden';
      body.style.position = 'fixed';
      body.style.width = '100%';
      body.style.height = '100%';
    } else {
      body.style.overflow = 'auto';
      body.style.position = 'static';
    }
  };

  const getHeaderClass = () => {
    switch (location.pathname) {
      case '/about':
        return 'about-header';
      case '/lore':
        return 'lore-header';
      case '/story':
        return 'story-header';
      case '/gallery':
        return 'gallery-header';
      default:
        return 'default-header';
    }
  };

  const getHamburgerColor = () => {
    switch(location.pathname) {
      case '/about':
        return 'black';
      case '/lore':
        return 'red';
      case '/gallery':
        return 'black';
      default:
        return 'red';
    }
  };
  
  const handleBrandClick = () => {
    const mobileMenu = document.querySelector('.mobile-menu');
    if (mobileMenu.classList.contains('open')) {
      toggleMobileMenu();
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    
    window.addEventListener("scroll", handleScroll);
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

    return (
    <header className={`${scrolled ? 'scroll-active' : ''} ${getHeaderClass()}`}>
      <div id="brand">
        <Link to="/" onClick={handleBrandClick}>
          <img src="./assets/zkAmurai.svg" alt="zkAmurai Logo" />
        </Link>
      </div>
      <div>
        <nav>
          <ul>
            {location.pathname === "/" && (
              <div>
                <img 
                  className="desktop-mute-button" 
                  src={muted ? "./assets/mute_desktop.svg" : "./assets/volume_desktop.svg"}
                  alt="Mute button" 
                  onClick={handleMute}
                />
              </div>
            )}
            <li>
              <a href="https://twitter.com/zk_amurai" target="_blank" rel="noreferrer noopener">
                <img src="./assets/twitter.svg" className="social-icon" alt="Twitter" />
              </a>
            </li>
            <li>
              <a href="https://discord.gg/FZRm6ErYeg" target="_blank" rel="noreferrer noopener">
                <img src="./assets/discord.svg" className="social-icon" alt="Discord" />
              </a>
            </li>
            <li>
              <Link to="/about">
                <span>About</span>
              </Link>
            </li>
            <li>
              <Link to="/lore">
                <span>Lore</span>
              </Link>
            </li>
            <li>
              <Link to="/gallery">
                <span>Gallery</span>
              </Link>
            </li>
            <li>
              <div className="connectivity">
                {!connected && (
                  <div onClick={connect}>Connect</div>
                )}
                {connected && (
                  <div onClick={disconnect}>Disconnect</div>
                )}
              </div>
            </li>
          </ul>
        </nav>
        <div id="mobile-nav-container">
          <div className="hamburger-mute-container">
            {location.pathname === "/" && (
              <img 
                className="mobile-mute-button" 
                src={muted ? "./assets/mute_mobile.svg" : "./assets/volume_mobile.svg"}
                alt="Mute button" 
                onClick={handleMute}
              />
            )}
          <div 
            id="hamburger-icon" 
            onClick={toggleMobileMenu} 
          >
            <div className="bar1" style={{backgroundColor: getHamburgerColor()}}></div>
            <div className="bar2" style={{backgroundColor: getHamburgerColor()}}></div>
            <div className="bar3" style={{backgroundColor: getHamburgerColor()}}></div>
          </div>
          </div>
          <ul className="mobile-menu">
            <li>
              <a href="https://twitter.com/zk_amurai" target="_blank" rel="noreferrer noopener">
                <span>Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://discord.gg/FZRm6ErYeg" target="_blank" rel="noreferrer noopener">
                <span>Discord</span>
              </a>
            </li>
            <li>
              <Link to="/about" onClick={toggleMobileMenu}>
                <span>About</span>
              </Link>
            </li>
            <li>
              <Link to="/lore" onClick={toggleMobileMenu}>
                <span>Lore</span>
              </Link>
            </li>
            <li>
              <Link to="/gallery" onClick={toggleMobileMenu}>
                <span>Gallery</span>
              </Link>
            </li>
            <li>
              <a href="https://www.zkmarkets.com/zksync-era/collections/0x205303ba7ee0d3098b29afed86158bf162ba125a" target="_blank" rel="noreferrer noopener" className="header-link button-clean button mobile-menu-link">
                <span>Buy on zkMarkets</span>
              </a>
            </li>
            <li>
              <div className="connectivity">
                {!connected && (
                  <div onClick={connect}>Connect</div>
                )}
                {connected && (
                  <div onClick={disconnect}>Disconnect</div>
                )}
              </div>
            </li>
            <div className="info">
              <span className="header-copyright">© 2023 zkAmurai</span>
              <span className="header-rights">All Rights Reserved</span>
              <span className="header-contact">contact@zkamurai.io</span>
            </div>
            <div className="header-image-2">
              <img src="./assets/mobile.webp" alt="Mobile Menu" />
            </div>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
