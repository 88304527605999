import React, { useEffect, useRef } from 'react';
import './css/lore.css';

const Story = () => {
    const myRef = useRef(); // Use the useRef hook to replace createRef

    useEffect(() => {
        const handleIntersect = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.remove("not");
                } else {
                    entry.target.classList.add("not");
                }
            });
        }

        let observer = new IntersectionObserver(handleIntersect, { threshold: 0.65 });

        let fadeItems = myRef.current.getElementsByClassName("story-hidden");
        for (let item of fadeItems) {
            item.classList.add("not");
            observer.observe(item);
        }

        // Cleanup: Disconnect observer when the component is unmounted
        return () => {
            for (let item of fadeItems) {
                observer.unobserve(item);
            }
            observer.disconnect();
        }
    }, []); // The empty array makes this run on mount and unmount

    return (
        <div className="story" ref={myRef}>
            <div className="image-wrapper">
                <img className="story-hidden" src="./assets/patrol1.webp" alt="On Patrol" />
            </div>
            <h1 className="story-hidden">On Patrol</h1>
            <div className="image-wrapper">
                <img src="./assets/redline-min.webp" alt="" />
            </div>
            <p className="story-hidden">Oni filled darkness has a peculiar smell.</p>
            <p className="story-hidden">This thought runs through the mind of our Kuroi Hana as she's out on patrol. She sitting alone, waiting on her scouts to return when the thought hits her mind, the smell on the nose.</p>
            <div className="image-wrapper">
                <img className="story-hidden" src="./assets/patrol2.webp" alt="On Patrol" />
            </div>
            <p className="story-hidden">Fear always comes with darkness; the lack of light brings a sense of despair she forgot how to feel.</p>
            <h2 className="story-hidden h2-spacing">Fear means death, Grand empress Linn taught her that..</h2>
            <p className="story-hidden">Now the smell of rotten fear lingering in the darkness reminds her of those arduous days spent training with her sisters, her new family, all of it to honor the fallen, to claim victory over the Oni, a dream they all know is for the far-flung future.</p>
            <div className="image-wrapper">
                <img className="story-hidden" src="./assets/patrol3.webp" alt="On Patrol" />
            </div>
            <p className="story-hidden">Her scouts return empty-handed</p>
            <div className="image-wrapper">
                <img className="story-hidden" src="./assets/patrol4.webp" alt="On Patrol" />
            </div>
            <h2 className="story-hidden h2-spacing">She keeps the disappointment story-hidden. </h2>
            <div className="image-wrapper">
                <img className="story-hidden" src="./assets/patrol5.webp" alt="On Patrol" />
            </div>
            <p className="story-hidden">A scream echoes through the night, the Kuroi Hana gives chase, her scouts falling behind with each lunging step. She arrives in the alley, red as crimson. For a moment her heart stinks. Was I too late? A woman lies nearby, cloaked in shadows and trash, she’s unharmed, but shaken.</p>
            <div className="image-wrapper">
                <img className="story-hidden" src="./assets/patrol6.webp" alt="On Patrol" />
            </div>
            <p className="story-hidden">An Oni taunts them from the end of the alley, she looks up and spots the mischievous Oni peeking around the corner, snickering loudly. The alley is plastered with eerie red neon signs, adding a sense of dread, obscuring the Oni in the distance.</p>
            <h2 className="story-hidden h2-spacing">The Oni's blood curdling growl resonates in the thick air</h2>
            <p className="story-hidden">The scouts arrive only to find their leader giving chase to the Oni, they lick their wounds by tending to the old woman.</p>
            <div className="image-wrapper">
                <img className="story-hidden" src="./assets/patrol7.webp" alt="On Patrol" />
            </div>
            <p className="story-hidden">The Kuroi Hana reaches the end of the alley, as she stands beneath the sign where the Oni once stood, a wicked breeze gusts through the alley. A portal. She knows the Oni is gone, but still, she stays alert.</p>
            <p className="story-hidden">She spots a wallet on the ground. She picks it up, opens it, she got there in time. No money was taken. A single stroke of red slides across a photo inside the wallet, beside it, a single drop of water.</p>
            <div className="image-wrapper">
                <img className="story-hidden" src="./assets/patrol8.webp" alt="On Patrol" />
            </div>
            <h2 className="story-hidden h2-spacing">A tear..</h2>
            <p className="story-hidden">The smell of rotten fear drifts away, replaced by the anguish of loneliness. She thanks her stars the Kuroi Hana are never alone.</p>
            <div className="image-wrapper">
                <img className="story-hidden" src="./assets/patrol9.webp" alt="On Patrol" />
            </div>
            <p className="story-hidden">The sisters are always together, even when apart, and Grand empress Linn is always in their hearts.</p>
            <div className="image-wrapper">
                <img src="./assets/redline-min.webp" alt="" />
            </div>
        </div>
    );
};

export default Story;
