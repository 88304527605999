import React, { createContext, useState } from "react";
import { ethers } from "ethers";

export const ConnectivityContext = createContext();

export const ConnectivityProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [name, setName] = useState("Please sign in");
  const [walletAddress, setWalletAddress] = useState("");

  const connect = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      await signer.signMessage("Welcome to zkAmurai!");
  
      const { ethereum } = window;
      if (ethereum) {
        const ensProvider = new ethers.providers.InfuraProvider("mainnet");
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        const displayAddress = address?.substr(0, 6) + "...";
        const ens = await ensProvider.lookupAddress(address);
        if (ens !== null) {
          setName("Welcome back..." + ens);
        } else {
          setName("Welcome back..." + displayAddress);
        }
  
        // Set the walletAddress state here
        console.log('Setting walletAddress:', address);
        setWalletAddress(address);
  
      } else {
        alert("No wallet detected!");
      }
      setConnected(true);
    } catch (error) {
      alert(error.message);
    }
  };

  const setWallet = (address) => {
    setWalletAddress(address);
  };

  const disconnect = async () => {
    setConnected(false);
    setName("Please sign in");
  };

  // The value passed to the provider will be accessible to all components
  return (
    <ConnectivityContext.Provider value={{ connected, connect, disconnect, name, walletAddress, setWallet }}>
      {children}
    </ConnectivityContext.Provider>
  );
};
