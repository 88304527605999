import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Rain from './Rain';
import './css/home.css';

function Home({ muted }) {
  const audio = useRef(new Audio('./assets/track.mp3'));
  const [opacity, setOpacity] = useState(0);
  const [showRain, setShowRain] = useState(false);
  const [flickerImageIndex, setFlickerImageIndex] = useState(0);

  const flickerDuration = 100;
  const minLightDuration = 1000;
  const maxLightDuration = 5000;

  const isSmallScreen = window.matchMedia("(max-width: 767px)").matches;

  const flickerImages = isSmallScreen 
  ? [
      "./assets/home_small_gallery_flicker.webp", 
      "./assets/home_small_zkamurai_flicker.webp", 
      "./assets/home_small_twitter_flicker.webp",
      "./assets/home_small_discord_flicker.webp"
    ] 
  : [
      "./assets/home_gallery_flicker.webp",
      "./assets/home_linn_flicker.webp",
      "./assets/home_buy_flicker.webp", 
      "./assets/home_about_flicker.webp", 
      "./assets/home_zkamurai_flicker.webp", 
      "./assets/home_twitter_flicker.webp", 
      "./assets/home_discord_flicker.webp", 
      "./assets/home_lore_flicker.webp"
    ];

  const normalImage = isSmallScreen ? "./assets/home_small.webp" : "./assets/home.webp";

  useEffect(() => {
    const flicker = () => {
      setOpacity(0.5);
      setTimeout(() => setOpacity(0), flickerDuration);
    };
    const switchImage = () => {
      flicker();
      setFlickerImageIndex(Math.floor(Math.random() * flickerImages.length));
      setTimeout(switchImage, Math.random() * (maxLightDuration - minLightDuration) + minLightDuration);
    };
    switchImage();

    setTimeout(() => setShowRain(true), 10);
    
    audio.current.loop = true;
    audio.current.muted = muted;
    const playPromise = audio.current.play();
    if (playPromise !== undefined) {
      playPromise.then(_ => {})
      .catch(error => {
        console.log('Audio play failed. User interaction might be required.', error);
      });
    }

    // Cleanup
    return () => {
      if(audio.current) {
        audio.current.pause();
      }
    }
  }, [muted]);

  return (
    <div className="home" style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      <div className="common-styles" style={{ backgroundImage: `url(${normalImage})` }} />
      <div className="common-styles" style={{ backgroundImage: `url(${flickerImages[flickerImageIndex]})`, opacity: opacity }} />
      {showRain && <Rain />}
      <Link 
        to="/gallery" 
        className="link-to-gallery"
      />
      <Link 
        to="/about" 
        className="link-to-about"
      />
      <Link 
        to="/lore" 
        className="link-to-lore"
      />
      <a 
        href="https://linns.substack.com/" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="link-to-substack"
      >
      </a>
      <a 
        href="https://twitter.com/zk_amurai" 
        target="_blank"
        rel="noreferrer noopener"
        className="link-to-twitter"
      >
      </a>
      <a 
        href="https://discord.gg/FZRm6ErYeg" 
        target="_blank"
        rel="noreferrer noopener"
        className="link-to-discord"
      >
      </a>
      <a 
        href="https://element.market/collections/zkamurai?search%5Btoggles%5D[0]=BUY_NOW" 
        target="_blank"
        rel="noreferrer noopener"
        className="link-to-buy"
      >
      </a>
    </div>
  );
}

export default Home;
