import React, { useState, useEffect } from 'react';
import './css/rain.css';

const Rain = () => {
  const [drops, setDrops] = useState([]);

  useEffect(() => {
    const nbDrop = 858;
    const newDrops = [];

    for(let i = 1; i < nbDrop; i++) {
      const dropLeft = randRange(0, window.innerWidth);
      const dropTop = randRange(-1000, 1400);
      
      newDrops.push({ id: i, left: dropLeft, top: dropTop });
    }

    setDrops(newDrops);
  }, []);

  const randRange = (minNum, maxNum) => {
    return (Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum);
  };

  return (
    <div className="rain">
      {drops.map(drop => (
        <div 
          key={drop.id}
          className="drop" 
          style={{
            left: drop.left,
            top: drop.top,
          }}
        />
      ))}
    </div>
  );
};

export default Rain;
