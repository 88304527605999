import React from 'react';
import './css/pre.css';

const Preloader = () => {
  return (
    <div className="preloader">
      <img src="./assets/goblin.svg" className="goblin-spinner" alt="Loading..." />
    </div>
  );
};

export default Preloader;
