import React, { useState, useEffect } from 'react';
import Preloader from './Pre';
import { ConnectivityProvider } from './Connectivity';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import About from './About';
// import Puzzle from './Puzzle';
// import LoreConstruction from './LoreConstruction';
import Lore from './Lore';
import Gallery from './Gallery';
import Footer from './Footer';
import './css/style.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [muted, setMuted] = useState(true);

  const handleMute = () => {
    setMuted(!muted);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <ConnectivityProvider>
      <Router>
        {isLoading && <Preloader />}
        <div className="App">
          <Header muted={muted} handleMute={handleMute} />
          <Routes>
            <Route path="/" element={<HomeWithFooter muted={muted} />} />
            <Route path="/about" element={<About />} />
            <Route path="/lore" element={<Lore />} />
            <Route path="/gallery" element={<Gallery />} />
          </Routes>
        </div>
      </Router>
    </ConnectivityProvider>
  );
}

function HomeWithFooter({ muted }) {
  return (
    <>
      <Home muted={muted} />
      <Footer />
    </>
  );
}

export default App;
