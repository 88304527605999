import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Type from "./Type";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/about.css';

const About = () => {
  const [imageID, setImageID] = useState(1);  // Start from image 1

  const handleClick = () => {
    const newImageID = Math.floor(Math.random() * 666) + 1; // Generate a random number between 1 and 666
    setImageID(newImageID);
  };

  return (
    <section>
      <div className="about">
        <div className="about-content">
          <div className="about-header">
            <h1 className="heading">
              いらっしゃいませ{" "}
              <span className="wave" role="img" aria-labelledby="wave">
                👺
              </span>
            </h1>

            <h1 className="heading-name">
              Welcome to
              <strong className="main-name"> zkAmurai</strong>
            </h1>

            <div className="type-container">
              <Type />
            </div>
            
            <div className="header-image" onClick={handleClick}>
              <img className="fade-in" src={`./assets/nft_images/${imageID}.webp`} alt="NFT" />
              <p className="nft-id">{`NFT ID: ${imageID}`}</p>
              <p className="nft-id">Check out the full collection on the <Link to="/gallery" className="gallery-link">Gallery</Link> page</p>
            </div>
          </div>

          <div className="about-texts">
            <section id="section1">
              <h1>Who are we?</h1>
              <p>zkAmurai is a collection of 666 unique NFTs on zkSync Era designed to protect your wallet from EVIL!</p>
              <p>What kind of EVIL you say? The kind that leaves you blind to the alpha ever present and ever evolving on a daily basis.</p>
            </section>
            <section id="section2">
              <h2>Our Mission</h2>
              <p>Our mission is two fold, to provide awesome <strong>Art</strong> and incredible <strong>Alpha</strong>.</p>
              <p>If you want to keep your edge in the DeFi space, you can't do much better than holding a zkAmurai NFT. This is your gateway to our exclusive <strong>Dojo</strong> Alpha channels in our discord, and top quality research from long-standing trusted members of the community, you can be sure you'll stay up to date on everything you need to know, and discover new opportunities you'd never imagine without holding your own zkAmurai!</p>
              <p>Don't be fooled by the zkAmurai Black Flower's appearance, every sister is a trained Oni killer and will bite!</p>
            </section>
            <section id="section3">
              <h2>Our World - Explore the Lore</h2>
              <p>Our world is growing, and our Lore page will soon let you delve into the rich story of zkAmurai and how they rose up to protect Zekami. A world ravaged by Oni, hell-bent on destruction which only the black Flowers of zkAmurai can stop!</p>
            </section>
            <section id="section4">
              <h2>Affiliates</h2>
              <p>zkAmurai is closely affiliated with Linn's Substack, our founder's Alpha Newsletter, with currently over 15 thousand subscribers it possibly/probably will make you a bit richer, but don't treat it as financial advice. Head over to <a href="https://linns.substack.com/" className="about-link">Linn's Substack</a> to check it out and get a 7-day free trial!</p>
              <div className="substack-image">
                <a href="https://linns.substack.com/" target="_blank" rel="noopener noreferrer">
                  <img src="./assets/substack.webp" alt="Linn's Substack" />
                </a>
              </div>
            </section>
            <section id="section4">
              <h2>Where to get your own!</h2>
              <p>We minted out in less than an hour! To get one of your own, head over to <a href="https://element.market/collections/zkamurai?search%5Btoggles%5D[0]=BUY_NOW" className="about-link">Element Market</a>.</p>
              <div className="element-image">
                <a href="https://element.market/collections/zkamurai?search%5Btoggles%5D[0]=BUY_NOW" target="_blank" rel="noopener noreferrer">
                  <img src="./assets/element.svg" alt="Element" />
                </a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
